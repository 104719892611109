import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Ico from './iconBox';

import port1 from '../assets/images/port (1).jpg';
import port2 from '../assets/images/port (2).jpg';
import port3 from '../assets/images/port (3).jpg';
import port4 from '../assets/images/port (4).jpg';
import port5 from '../assets/images/port (5).jpg';
import port6 from '../assets/images/port (6).jpg';
import port7 from '../assets/images/port (7).jpg';

// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
            {/* End Main Top */}
            {/* Start Top Search */}
            
            {/* End Top Search */}
            {/* Start All Title Box */}
            
            {/* End All Title Box */}
            {/* Start About Page  */}





            <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">Portfolio</h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index-32.html">Home</a></li>
                <li className="breadcrumb-item"><a >Our Portfolio</a></li>
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}



        

<div className='container-fuild' style={{margin : "30px auto"}}>
            <div className='row'  >
            <div className="col-lg-4 col-sm-12" style={{margin  :"auto"}}>
                <img src={port1} alt="" style={{height:"400px",padding:"20px",width:"100%"}} />
            </div>
            <div className="col-lg-4 col-sm-12" style={{margin  :"auto"}}>
                <img src={port2} alt="" style={{height:"400px",padding:"20px",width:"100%"}} />
            </div>
            <div className="col-lg-4 col-sm-12" style={{margin  :"auto"}}>
                <img src={port3} alt="" style={{height:"400px",padding:"20px",width:"100%"}} />
            </div>
            <div className="col-lg-4 col-sm-12" style={{margin  :"auto"}}>
                <img src={port4} alt="" style={{height:"400px",padding:"20px",width:"100%"}} />
            </div>
            <div className="col-lg-4 col-sm-12" style={{margin  :"auto"}}>
                <img src={port5} alt="" style={{height:"400px",padding:"20px",width:"100%"}} />
            </div>
            <div className="col-lg-4 col-sm-12" style={{margin  :"auto"}}>
                <img src={port6} alt="" style={{height:"400px",padding:"20px",width:"100%"}} />
            </div>
            <div className="col-lg-4 col-sm-12" style={{margin  :"auto"}}>
                <img src={port7} alt="" style={{height:"400px",padding:"20px",width:"100%"}} />
            </div>
            </div>
            </div>


          


            {/* </div> */}
        
          </div>
        )
    }
}

export default About;